import { Injectable } from '@angular/core';
import {
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Util } from './util';
import { Component, Input } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Location } from '@angular/common';

@Injectable()
export class HttpServer {
  private apiUrl = '';
  private apiKey = '';


  constructor(
    private util: Util,
    private loc: Location
  ) {
  }

  SendData(
    model: any,
    url: any,
    mode: string,
    isBannerNeed = true,
    isDirectUrl = false,
    token = null,
    bannerId = '1',
  ) {

    if (isDirectUrl === true) {
      url = url;
    }
    else {

      if (this.util.isProd()) {
        url = environment.prodShoreAPI + '/' + url;
      }
      else if (this.util.isUAT()) {
        url = environment.uatShoreAPI + '/' + url;
      }
      else if (this.util.isDev()) {
        url = environment.devShoreAPI + '/' + url;
      }
      else {
        url = environment.uatShoreAPI + '/' + url;
      }
    }

    let headers: any;
    headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: '',
      Banner: ''
    };

    if (url.includes("7py8fahnf3") || url.includes("llz1npmgm7")) {
      if (bannerId != null && bannerId.toString() == "2") {
        headers.Banner = 'lucky';
      }
      else if (bannerId != null && bannerId.toString() == "3") {
        headers.Banner = 'foodmaxx';
      }
      else {
        headers.Banner = 'savemart';
      }
    }

    if (!this.util.IsNotEmpty(headers.Banner)) {
      delete headers.Banner
    }
    if (!this.util.IsNotEmpty(token)) {
      token = this.util.getToken();
    }

    if (this.util.IsNotEmpty(token)) {
      headers.Authorization = 'Bearer ' + token;
    }

    if (!this.util.IsNotEmpty(headers.Authorization)) {
      delete headers.Authorization;
    }
    // delete headers.Authorization;

    return fetch(url, {
      method: mode ? mode.toUpperCase() : 'GET',
      headers,
      body: model ? JSON.stringify(model) : null,
    }).
      then((Response) => Response.clone().json()).
      catch(function () {
        console.log("error");
      });
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }


  getAuthToken(): Promise<string> {
    this.apiUrl = 'https://us-west2-tsmc-workbench-dev.cloudfunctions.net/getAuthToken';
    this.apiKey = 'vA4hWVw85E6yxeniw7c8NJY8oVVTbeVk_aoo2V1rDug=:gAAAAABnx-BlMhmdn9KohaZcMeP7JEmD1n1H3xxLGwUkkrB-DMwBaUGox7hgj5f537gCrUY9i5_a9zu8EsiA1Af66g-sIS0WLHiycejS7pbu_CF0y4XPpGt19qRu-8FsIeI59zD99uel20NApJHYi46_erflHnBYpmLU2Niqa6v84FF9MLVRci4=';
    this.util.setlocation(location);
    if (this.util.isProd()) {
      this.apiUrl = 'https://us-west2-tsmc-workbench-prod.cloudfunctions.net/GetAuthToken';
      this.apiKey = 'ZobDp9r6hqkh3HK9Iem1Rq7TvmA_PqKZR96DQtqPhmc=:gAAAAABnyY-8YajcTtQfoX-N5Cku99shT3wXI4h1DqmhlEOC53IKuiSJug5gki1c5dbZrQlX5t_qAhecdOCHyU6girMmf6pXVD8dt0XfYJkU-7C0N-ch0jTjc_IkqhHDhF2z6Qt8nSlYnK1VpMV_t2URJRcjrCKDqMXjUIRJaGF2GrgJps-Cs90=';
    }
    
  
    const headers = {
      'Authorization': `Bearer ${this.apiKey}`
    };
  
    return fetch(this.apiUrl, { headers })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Failed to fetch token: ${response.statusText}`);
        }
        return response.json();
      })
      .then(data => data.bearer) // Use `data.bearer` to get the token
      .catch(error => {
        console.error('Error fetching auth token:', error);
        throw error;
      });
  }

}
